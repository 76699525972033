import Strapi from 'strapi-sdk-js';

const strapi = new Strapi({
  url: 'https://futuriza-core-api-brxm7jvfmq-rj.a.run.app/'
});



const logAnalytics = async (session_id, store_id, product_id, event_type='load', feature="view3D", plataform='webMobile', count=1) => {
        await strapi.create('analytics', { date: new Date().getTime(), sessionID: session_id, store: store_id, product: product_id, count: count, eventType: event_type,feature:feature,plataform:plataform});
    
}

export default logAnalytics;