import * as React from "react";
import * as ReactDOM from "react-dom/client";
import FaceAccessory from './face-accessory';
import ReactHandTracking from './hand';
import FaceMesh from './face-mesh';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import Ears from "./ears";

const router = createBrowserRouter([
  {
    path: "/",
    element: <div><h1>FUTURIZA</h1></div>,
  },
  {
    path: "/face",
    element: <FaceAccessory/>,
  },
  {
    path: "/hands",
    element: <ReactHandTracking/>,
  },
  {
    path: "/face-mesh",
    element: <FaceMesh/>,
  },
  {
    path: "/ears",
    element: <Ears/>,
  }
]);

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(  <RouterProvider router={router} />);
