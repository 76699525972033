import { useEffect, useRef, useState } from "react";
import { MindARThree } from "mind-ar/dist/mindar-face-three.prod.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import * as THREE from "three";

import { OrbitControls } from "three/addons/controls/OrbitControls.js";

let mindarThree;

const Ears = () => {
  const [isMobile, setIsMobile] = useState(false);
  const validateMobile = () => {
    setIsMobile(/iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent));
  };
  const containerRef = useRef(null);

  useEffect(() => {
    const get3D = async () => {
      //TODO: Pegar o modelo dinamicamente da lista e recebendo o nome do modelo por parametros......
      start('/glasses.glb');
    };

    const start = async (glbFile) => {
      mindarThree = new MindARThree({
        container: containerRef.current,
        filterMinCF: 10,
      });

      const { renderer, scene, camera } = mindarThree;
      const light2 = new THREE.DirectionalLight(0xffffff, 0.1);
      light2.position.set(-0.5, 1, 1);

      new RGBELoader().load("/sky.hdr", function (texture) {
        texture.mapping = THREE.EquirectangularReflectionMapping;
        scene.environment = texture;

        scene.environment = texture;
        scene.environmentIntensity = 0.1;

        const glassesLoader = new GLTFLoader();

        glassesLoader.load(glbFile, (glasses) => {
          glasses.scene.scale.set(
            parseFloat(scale[0]),
            parseFloat(scale[1]),
            parseFloat(scale[2])
          );
          glasses.scene.position.set(
            parseFloat(position[0]),
            parseFloat(position[1]),
            parseFloat(position[2])
          );

          //recieve shadows
          glasses.scene.traverse((o) => {
            if (o.material) {
              o.material.side = THREE.DoubleSide;
            }

            if (o.isMesh) {
              o.material.reflectivity = 0;
            }
          });

          const earningLeftAnchor = mindarThree.addAnchor(401); //Mais próximo a orelha esquerda
          
          earningLeftAnchor.group.add(glasses.scene);
         

          glasses.scene.environment.reflectivity = 0;
        });
      });

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.update();

      // Load occluder
      const occluderLoader = new GLTFLoader();
      occluderLoader.load("headOccluder.glb", (occluder) => {
        occluder.scene.scale.set(0.065, 0.065, 0.065);
        occluder.scene.position.set(0, -0.3, 0.15);
        occluder.scene.traverse((o) => {
          if (o.isMesh) {
            const occluderMaterial = new THREE.MeshPhongMaterial({
              colorWrite: false,
            });
            o.material = occluderMaterial;
          }
        });
        occluder.scene.renderOrder = 0;

        const occluderAnchor = mindarThree.addAnchor(168);
        occluderAnchor.group.add(occluder.scene);
      });

      const scale = ["0.5", "0.5", "0.5"];
      const position = ["0", "0", "0"];
  
      await mindarThree.start();
      renderer.setAnimationLoop(() => {
        controls.update();
        renderer.render(scene, camera);
      });
    };
    validateMobile();
    get3D();
  }, []);

  const screenHeight = window.innerHeight / 1.6;
  return (
    <>
      <div
        style={{
          top: 0,
          left: 0,
          height: screenHeight,
          width: "100%",
          position: "relative",
        }}
        ref={containerRef}
      ></div>
    </>
  );
};

export default Ears;
