import { useEffect, useRef, useState } from "react";
import { MindARThree } from "mind-ar/dist/mindar-face-three.prod.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import * as THREE from "three";
import Strapi from "strapi-sdk-js";
import logUsage from "./service/usage";
import logAnalytics from "./service/analytics";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";

const strapi = new Strapi({
  url: "https://futuriza-core-api-brxm7jvfmq-rj.a.run.app/",
});

let mindarThree;

const FaceAccessory = () => {
  const [isMobile, setIsMobile] = useState(false);

  const validateMobile = () => {
    setIsMobile(/iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent));
  };

  const logEvent = (eventName) => {
    window.parent.postMessage(
      {
        type: "sendGAEvent",
        data: { eventName: eventName, eventCategory: "", eventLabel: "" },
      },
      "*"
    );
  };

  const containerRef = useRef(null);

  useEffect(() => {
    const get3D = async () => {
      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);
      const externalID = params.get("externalID");
      const sessionID = params.get("sessionID");

      const token = params.get("token");
      const response = await strapi.find("products", {
        filters: {
          external_id: externalID,
          store: {
            apiToken: {
              $eq: token,
            },
          },
        },
        populate: ["glb_file", "arParams", "store"],
      });

      const dataList = response["data"];

      if (dataList?.length === 0) {
        return <b>3D not found.</b>;
      }

      const product = dataList[0];
      const glbFile =
        product["attributes"]["glb_file"]["data"][0]["attributes"]["url"];
      const storeID = product["attributes"]["store"]["data"]["id"];

      const plataform = isMobile ? "webMobile" : "webDesktop";
      try {
        await logUsage(storeID, product.id, "tryOn");
        await logAnalytics(
          sessionID,
          storeID,
          product.id,
          "load",
          "tryOn",
          plataform,
          1
        );
      } catch (error) {}
      start(glbFile, product["attributes"]["arParams"]);
    };

    const start = async (glbFile, params) => {
      mindarThree = new MindARThree({
        container: containerRef.current,
        filterMinCF: 10,
      });

      const { renderer, scene, camera } = mindarThree;
      const light2 = new THREE.DirectionalLight(0xffffff, 0.1);
      light2.position.set(-0.5, 1, 1);

      new RGBELoader().load("/sky.hdr", function (texture) {
        texture.mapping = THREE.EquirectangularReflectionMapping;
        scene.environment = texture;

        scene.environment = texture;
        scene.environmentIntensity = 0.1;

        const glassesLoader = new GLTFLoader();

        glassesLoader.load(glbFile, (glasses) => {
          glasses.scene.scale.set(
            parseFloat(scale[0]),
            parseFloat(scale[1]),
            parseFloat(scale[2])
          );
          glasses.scene.position.set(
            parseFloat(position[0]),
            parseFloat(position[1]),
            parseFloat(position[2])
          );

          //recieve shadows
          glasses.scene.traverse((o) => {
            if (o.material) {
              o.material.side = THREE.DoubleSide;
            }

            if (o.isMesh) {
              o.material.reflectivity = 0;
            }
          });

          const glassesAnchor = mindarThree.addAnchor(168);
          glassesAnchor.group.add(glasses.scene);
          glasses.scene.environment.reflectivity = 0;
        });
      });

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.update();

      // Load occluder
      const occluderLoader = new GLTFLoader();
      occluderLoader.load("headOccluder.glb", (occluder) => {
        occluder.scene.scale.set(0.065, 0.065, 0.065);
        occluder.scene.position.set(0, -0.3, 0.15);
        occluder.scene.traverse((o) => {
          if (o.isMesh) {
            const occluderMaterial = new THREE.MeshPhongMaterial({
              colorWrite: false,
            });
            o.material = occluderMaterial;
          }
        });
        occluder.scene.renderOrder = 0;

        const occluderAnchor = mindarThree.addAnchor(168);
        occluderAnchor.group.add(occluder.scene);
      });

      const scale = params["scale"].split(" ");
      const position = params["position"].split(" ");

      await mindarThree.start();
      renderer.setAnimationLoop(() => {
        controls.update();
        renderer.render(scene, camera);
      });
    };
    validateMobile();
    get3D();
    logEvent("try_on");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const screenHeight = window.innerHeight / 1.6;
  return (
    <>
      <div
        style={{
          top: 0,
          left: 0,
          height: screenHeight,
          width: "100%",
          position: "relative",
        }}
        ref={containerRef}
      ></div>
    </>
  );
};

export default FaceAccessory;
