import React, { useEffect, useRef, memo, useState } from "react"
import { FilesetResolver, GestureRecognizer } from "@mediapipe/tasks-vision"
import * as THREE from "three";

let gestureRecognizer
let lastVideoTime = -1

const generateResult = results => {
  const result = []
  for (let i = 0; i < results.handednesses.length; i++) {
    result.push({
      hand: {
        handType:
          results.handednesses[i][0].categoryName === "Right" ? "Left" : "Right",
        confidence: results.handednesses[i][0].score
      },
      gesture: {
        gestureType: results.gestures[i][0].categoryName,
        confidence: results.gestures[i][0].score
      },
      landmarks: results.landmarks[i]
    })
  }

  

  return result
}

var objectPosition = {x: 1.8149382472038269, y: 0.6570871472358704,z: 3.197599482973601e-7};
  


const ReactHandTracking = ({
    
  callback = result => {},
  videoResolution = { width: 1280, height: 720 },
  recognizerOptions = { numHands: 1, detectionThreshold: 0.5 },

  predictionTimeout = 0
}) => {
   useEffect(() =>{
    const scene = new THREE.Scene();

    // Configurando a câmera
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 5;

    // Configurando o renderizador
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    const mainDiv = document.getElementById('filha1');
    mainDiv.appendChild(renderer.domElement);

    // Adicionando uma esfera à cena
    const geometry = new THREE.SphereGeometry();
    const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    const sphere = new THREE.Mesh(geometry, material);
    scene.add(sphere);

    // Adicionando uma luz à cena
    const light = new THREE.PointLight(0xffffff);
    light.position.set(10, 10, 10);
    scene.add(light);

    // Atualizando a cena
    function animate() {
        requestAnimationFrame(animate);
        sphere.position.set(objectPosition.x, objectPosition.y, objectPosition.z);
        //console.log(objectPosition);
        // Rotacionando a esfera
        sphere.rotation.x += 0.01;
        sphere.rotation.y += 0.01;


        renderer.render(scene, camera);
    }

    animate();
   },[]) 

  const video = useRef(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setup = async () => {
    if (!video.current) return
    const vision = await FilesetResolver.forVisionTasks(
      "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.2-rc2/wasm"
    )

    gestureRecognizer = await GestureRecognizer.createFromOptions(vision, {
      baseOptions: {
        modelAssetPath:
          "https://storage.googleapis.com/mediapipe-tasks/gesture_recognizer/gesture_recognizer.task",
        delegate: "GPU"
      },
      numHands: recognizerOptions.numHands,
      runningMode: "VIDEO",
      minHandDetectionConfidence: recognizerOptions.detectionThreshold,
      minHandPresenceConfidence: recognizerOptions.detectionThreshold,
      minTrackingConfidence: recognizerOptions.detectionThreshold
    })

    navigator.mediaDevices
      .getUserMedia({ video: videoResolution, audio: false })
      .then(stream => {
        video.current.srcObject = stream
        video.current.addEventListener("loadeddata", predict)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const predict = () => {
    const nowInMs = Date.now()
    if (video.current && lastVideoTime !== video.current.currentTime) {
      lastVideoTime = video.current.currentTime
      const results = gestureRecognizer.recognizeForVideo(
        video.current,
        nowInMs
      )
        

      const res = generateResult(results);

      if ( res.length > 0){
        // console.log(res[0].landmarks);
        const land = res[0].landmarks[10];
        objectPosition = land;
        // alert(JSON.stringify(land[0]));
        // console.log('::::MUDEI' + land[0])
        // objectPosition = land[0];
     }

      callback(generateResult(results))
    }
    setTimeout(() => requestAnimationFrame(predict), predictionTimeout)
  }

  useEffect(() => {
    setup()
  }, [video, recognizerOptions, setup])

  return (
    <div class="principal">
         <div class="filha filha2">Div Filha 2
    <video ref={video} autoPlay />
    </div>
    <div id="filha1" class="filha filha1">Div Filha 1</div>
   
</div>


  )
}

export default memo(ReactHandTracking, (prevProps, nextProps) => {
  if (
    prevProps.predictionTimeout != nextProps.predictionTimeout ||
    prevProps.recognizerOptions != nextProps.recognizerOptions ||
    prevProps.videoResolution != nextProps.videoResolution
  ) {
    return false
  } else return true
})
