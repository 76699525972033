import Strapi from 'strapi-sdk-js';

const strapi = new Strapi({
  url: 'https://futuriza-core-api-brxm7jvfmq-rj.a.run.app/'
});


const logUsage = async (store_id, product_id, typeCall="apiCall", count=1) => {
        await strapi.create('usages', { date: new Date().getTime(), store: store_id, product: product_id, count: count, type: typeCall});
    
}

export default logUsage;


// try{
//     await logUsage(storeID,product.id,"view3D");
//     setURL(glbFile);
//    setKey(new Date().toISOString());

//    }catch(e){
//      console.error(e);
//    }